export let endpoints = (request = {}) => {
    
    let request_headers = null;
    let request_headers_json = null;
    let user_id = null;
    let client_params = null;

    if(request != null) {
        
        if(request.token) {
            request_headers_json = { 'Authorization' : 'Bearer '+request.token, 'Content-Type' : 'application/json' };
        } else {
            request.token = null;
        }
        
        if(request.user_id) {
            user_id = request.user_id;
        }

        if(request.params) {
            client_params = request.params;
        }

    } else {
        request = {};
    }


    return { 


        /* ################################ AUTHENTICATION ENDPOINTS ################################ */
        
        authentication_login: {
            url: '/api/v1/authenticate-admin',
            method: 'POST',
            headers: {'Content-Type' : 'application/json' },
            isFileUpload : false
        },

        /* ################################ AUTHENTICATION ENDPOINTS ################################ */




        /* ################################ MEMBER CRUD ENDPOINTS ################################ */

        event_list : {
            url: '/api/v1/event/active',
            method: 'POST',
            headers: request_headers_json,
            isFileUpload : false
        },

        event_attendance : {
            url: '/api/v1/attendance/checkAttendance',
            method: 'POST',
            headers: request_headers_json,
            isFileUpload : false
        },
    }
}