import React, {Component} from 'react';
import { api } from '../api.js';
import moment from 'moment';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: moment().format('HH:mm:ss'),
      currentDate: moment().format('dddd, MMMM DD, YYYY'),
      currentEventLabel: "No Active Event",
      currentEvent: null,
      goodman_id: "",

      member_photo: "",
      member_first_name: "Juan Ignacio",
      member_last_name: "Dela Cruz",
      member_type: "Associate Minister"
    };

    this.goodmanId = React.createRef();
    this.goodmanIdForm = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  async componentDidMount() {
    await this.fetchEvents();

    // this is used for the clock display, 3AM update of all events, and every minute update of event display
    this.intervalID = setInterval(() => {
      this.setState({
        currentTime: moment().format('HH:mm:ss'),
        currentDate: moment().format('dddd, MMMM DD, YYYY'),
      });

      /* ################################## THIS BLOCK WILL UPDATE ALL EVENTS FROM LOCAL STORAGE EVERY 3 AM ################################## */
      
      let currentTime = moment();
      let isThreeAM = currentTime.hours() === 3 && currentTime.minutes() === 0 && currentTime.seconds() === 0;
      if (isThreeAM) {
        this.fetchEvents();
      }

      /* ################################## THIS BLOCK WILL UPDATE ALL EVENTS FROM LOCAL STORAGE EVERY 3 AM ################################## */

    }, 1000);

    /* ################################## THIS BLOCK WILL UPDATE THE CURRENT EVENT DISPLAY ON THE SCREEN ################################## */
    
    this.eventUpdateId = setInterval(() => {
      this.updateCurrentEventDisplay();
    }, 36000);

    /* ################################## THIS BLOCK WILL UPDATE THE CURRENT EVENT DISPLAY ON THE SCREEN ################################## */
  }

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  componentWillUnmount() {
    clearInterval(this.intervalID); // Cleanup the interval on unmount
    clearInterval(this.eventUpdateId); // Cleanup the interval on unmount
  }

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  updateCurrentEventDisplay = () => {
    let events = JSON.parse(localStorage.getItem('events'));
    let filteredEvent = events.filter(event => moment().isBetween(moment(event.startTime, "HH:mm:ss"), moment(event.endTime, "HH:mm:ss")));
    
    if(filteredEvent.length > 0) {
      let event = filteredEvent[0];
      let eventName = filteredEvent[0].eventName;
      this.setState({ currentEventLabel : eventName, currentEvent : event });
    }
  }

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  fetchEvents = async () => {
    let current_date = moment().format('YYYY-MM-DD');
    let that = this;
    let token = localStorage.getItem("auth_token");
    let headerReq = { token }
    let postData = { "dateTimeStart" : current_date };

    that.setState({isLoading : true});

    api("event_list", headerReq, postData, "event")
      .then((result) => {

        localStorage.setItem('events', JSON.stringify(result));
        this.updateCurrentEventDisplay();

      }).catch((error) => {

          console.log(error);
          console.log("Something went wrong. " + JSON.stringify(error));

      });
  }

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  handleSubmit(submit) {
    let that = this;
    let currentEvent = this.state.currentEvent;
    let token = localStorage.getItem("auth_token");
    let headerReq = { token }
    let goodman_id = this.state.goodman_id;

    let events = [];
    if(currentEvent != null) {
      events.push(currentEvent);
    }

    if(events.length == 0) {
      this.notify("error", "There is no active event at the moment");

      this.setState({
        goodman_id: "",
      });
    } else {

      let parts = goodman_id.split('-');
      let lastEntry = parts[parts.length - 1];

      let postData = {
      "checkInAttendanceDTO" : {
        "memberId" : lastEntry,
        "checkInType": "Walk-In"
      },

      "events" : events
    };

    api("event_attendance", headerReq, postData, "event")
      .then((result) => {

        this.setState({
          member_photo: result[0].photo,
          member_first_name: result[0].firstName,
          member_last_name: result[0].lastName,
          member_type: result[0].memberType,
          goodman_id: "",
        });

        this.notify("success", "Attendance was succsesfully submitted");

      }).catch((error) => {

        if(error.status == 400) {
          this.notify("error", "The user with this ID doesnt exist");
        }

        if(error.status == 500) {
          this.notify("error", "There was a server error");
        }
      });
    }


    setTimeout(function(){
      this.setState({
        member_photo: "",
        member_first_name: "Juan Ignacio",
        member_last_name: "Dela Cruz",
        member_type: "Associate Minister",
        goodman_id: ""
      });

      this.goodmanId.current.focus();
    }.bind(this),3000);  // wait 5 seconds, then reset to false

    submit.preventDefault();
  }

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  handleChange = (event) => {
    this.setState({goodman_id: event.target.value});
    this.goodmanIdForm.current.dispatchEvent(new Event('submit'));
  }

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  notify = (type, message) => {
    if(type == "error") {
      toast.error(
        message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    } else {
      toast.success(
        message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  }

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  render() {
    return (
      <div>
        <div className="main-wrapper">
          <div className="content-wrapper">
            <div className="vertical-align">
              <div className="main-content-wrapper">
                
                <div className="row">
                  <div className="col-md-6 position-relative">
                    <div className="vertical-align">
                      <center>
                        <div id="service-title" className="text-uppercase">{ this.state.currentEventLabel }</div>
                        <div id="displayTime">{this.state.currentTime}</div>
                        <div id="displayDate" className="text-uppercase">{ this.state.currentDate }</div>
                      </center>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <form ref={this.goodmanIdForm} id="qr-scanning" method="POST" onSubmit={this.handleSubmit}>
                      <center>
                        <div id="profile-wrapper">
                          <div style={{ backgroundImage : `url(${this.state.member_photo})` }} id="profile-image"></div>
                          <div id="profile-name">
                            <div id="given-name" className="text-uppercase full-lenght">{this.state.member_last_name}, {this.state.member_first_name}</div>
                            <div id="church-designation" className="text-uppercase full-lenght">{this.state.member_type}</div>
                          </div>
                          <input ref={this.goodmanId} autoFocus id="status-remark" name="goodman_id" type="text" value={this.state.goodman_id} onChange={this.handleChange} />
                        </div>
                      </center>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          />
      </div>
    );
  }
}

export default Dashboard;