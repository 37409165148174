import React, { useState, Navigate } from 'react';
import logo from '../assets/logo.png';
import { api } from '../api.js';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    let postData = { email, password };
    api("authentication_login", null, postData, "login")
      .then((result) => {

        localStorage.setItem("authenticated", true);
        localStorage.setItem("auth_token", result.token);
        localStorage.setItem("auth_name", result.name);
        localStorage.setItem("auth_customId", result.customId);
        localStorage.setItem("auth_userLevel", result.userLevel);
        localStorage.setItem("auth_localId", result.localId);
        localStorage.setItem("auth_ AccessId", result.AccessId);
        window.location.replace('/dashboard');

      }).catch((error) => {

          console.log(error);
          console.log("Something went wrong. " + JSON.stringify(error));

      });
  };

  return (
    <div id="login-page">
      <section className="full-page">
        <div className="vertical-align">
          <div className="card-header text-center">
              <img id="login-logo" src={logo} alt="logo" />
              <h1 id="login-header" className="text-uppercase">Please enter your login credential</h1>
            </div>
          <div id="loginForm" className="card card-primary">
            <div className="card-body">
              <form onSubmit={handleLogin}>
                <div className="row">
                  <div className="col-md-12 login-rows">
                    <div class="form-group">
                      <label>Username</label>
                      <input className="form-control"
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 login-rows">
                    <div class="form-group">
                      <label>Password</label>
                      <input
                        placeholder="Password"
                        className="form-control"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="text-center">
                      <hr />
                      <button id="login-button" className="btn btn-success brn-lg login" type="submit">Login</button>
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginForm;