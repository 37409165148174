import {Component} from 'react';
import { BrowserRouter as Router, Route, Link, Routes, Navigate} from 'react-router-dom';
import routes from './Routes/Routes';
import PrivateRoute from './Routes/PrivateRoute';

import Login from './Pages/login';
import background from './assets/bg.jpg';

import "bootstrap/dist/css/bootstrap.min.css";

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenSidebar: true,
    };
  }

  render() {
    const isAuthenticated = localStorage.getItem("authenticated");

    return (
      <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', minHeight: '100vh' }}>
        <div id="mainWrapper">
          <div id="outer-container">
            <div id="page-wrap">
              <Router>
                <Routes>
                  <Route
                    path="/"
                    exact={true}
                    element={
                      (isAuthenticated) ? (<Navigate to="/dashboard" />)
                      :
                      <Login isAuthenticated={isAuthenticated} />
                    }
                  />
                </Routes>

                {routes.map((route, index) => (
                  <PrivateRoute
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    element={route.element}
                    privateRoute={route.private} // Pass the privateRoute flag
                    isAuthenticated={isAuthenticated}
                  />
                ))}
              </Router>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;