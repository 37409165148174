import Dashboard from '../Pages/dashboard';

const routes = [
  {
    path: '/dashboard',
    element: <Dashboard />,
    exact: true,
  },
];

export default routes;